<!--
 * @Author: xiaziwen
 * @Date: 2021-01-25 17:13:11
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-25 17:36:05
 * @FilePath: \crm-education\src\views\protal\news\index.vue
-->
<template>
  <div class="protal-list-wrap">
    <div class="sider-menu">
      <a-menu mode="inline" v-model="subMenuSelectKeys" @click="onSubMenuClick">
        <a-menu-item v-for="menu in subMenuList" :key="menu.columnsTypeCode">
          {{ menu.columnsName }}
        </a-menu-item>
      </a-menu>
    </div>
    <div class="protal-list">
      <div class="page-title py-20">
        <p class="color-gray fs-14">
          首页><span class="color-black">{{ currentColumn.columnsName }}</span>
        </p>
      </div>
      <div class="news-list-wrap py-20">
        <div class="news-list">
          <div
            class="news-item color-gray  d-flex align-center link my-10"
            v-for="(item, index) in list"
            :key="index"
            @click="toDetail(item)"
          >
            <div class="cover-wrap">
              <img :src="item.informationCoverImage" alt="" />
            </div>
            <div class="detail-wrap d-flex align-center p-10 px-15">
              <div class="news-detail px-10">
                <p class="fs-16 mb-15 color-black">
                  {{ item.informationTitle }}
                </p>
                <p class="fs-14 line-elipsis-2">
                  {{ item.informationIntro }}
                </p>
              </div>
              <div class="news-date">
                <p class="fs-18">{{ item.createTime[0] }}</p>
                <p class="fs-32">{{ item.createTime[1] }}-{{ item.createTime[2] }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/api'
import { getCurrentByCode, getCurrentByColumnsId, getSubMenuByCode } from '@/utils/protal'
export default {
  name: 'protal_list',
  data() {
    return {
      list: [],
      currentColumn: {},
      subMenuList: [],
      subMenuSelectKeys: []
    }
  },
  computed: {
    ...mapState(['columnsInfo'])
  },
  watch: {
    $route() {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.columnsInfo.length > 0) {
        const { columnsTypeCode } = this.$route.query
        this.currentColumn = getCurrentByCode(this.columnsInfo, columnsTypeCode)
        this.subMenuList = getSubMenuByCode(this.columnsInfo, columnsTypeCode)
        this.subMenuSelectKeys = [this.currentColumn.columnsTypeCode]
        this.initInfo()
      }
    },
    async initInfo() {
      let res = await api.protal.getInfoByColumnId({
        columnsId: this.currentColumn.columnsId
      })
      if (res.code === 200) {
        this.list = res.data.sort(compare('isTop'))
      }
      function compare(property) {
        return function(a, b) {
          var value1 = a[property]
          var value2 = b[property]
          if (value1 > value2) {
            return -1
          }
          return 1
        }
      }
    },
    toDetail(item) {
      this.$router.push({
        path: '/protal/list/detail',
        query: {
          columnsId: item.columnsId,
          informationId: item.informationId,
          columnsTypeCode: this.currentColumn.columnsTypeCode
        }
      })
    },
    async onSubMenuClick(menu) {
      const current = getCurrentByCode(this.columnsInfo, menu.key)

      if (menu.key !== '/protal') {
        let path = '/protal/list'
        if (current.columnsShowModel == 1) {
          path = '/protal/list'
          this.$router.push({
            path: path,
            query: { columnsTypeCode: menu.keyPath[0] }
          })
        } else {
          path = '/protal/list/detail'
          let res = await api.protal.getInfoByColumnId({
            columnsId: current.columnsId
          })
          if (res.code === 200 && res.data.length === 1) {
            this.$router.push({
              path: path,
              query: {
                columnsTypeCode: menu.keyPath[0],
                columnsId: current.columnsId,
                informationId: res.data[0].informationId
              }
            })
          } else {
            this.$router.push({
              path: path,
              query: {
                columnsTypeCode: menu.keyPath[0],
                columnsId: current.columnsId
              }
            })
          }
        }
      } else {
        this.$router.push({
          path: '/protal'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.protal-list-wrap {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}
.protal-list {
  flex: 1;
  .news-list-wrap {
    .news-item {
      border-radius: 5px;
      overflow: hidden;
      .cover-wrap {
        width: 200px;
        height: 120px;
        img {
          height: 120px;
          object-fit: fill;
        }
      }
      &:hover {
        .detail-wrap {
          background-color: #fa3f3f;
          color: #fff !important;
          p {
            color: #fff !important;
            background-color: #fa3f3f !important;
          }
        }
        box-shadow: 0 0 9px 1px rgba(0, 0, 0, 0.3);
      }
      .detail-wrap {
        height: 120px;
        flex: 1;
      }
      .news-date {
        width: 140px;
        padding: 10px 10px 10px 20px;
      }
      .news-detail {
        flex: 1;
      }
    }
  }
}
.sider-menu {
  width: 240px;
  height: min-content;
  margin: 72px 20px 20px 0;
  border: 1px solid #eee;
  ::v-deep .ant-menu-item::after {
    border-right: 3px solid #fa3f3f;
  }
  ::v-deep .ant-menu-item.ant-menu-item-selected {
    background-color: #fa3f3f;
    color: #fff;
  }
  ::v-deep .ant-menu-item.ant-menu-item-selected:hover {
    color: #fff;
  }
  ::v-deep .ant-menu-item:hover {
    color: #fa3f3f;
  }
  ::v-deep .ant-menu-inline {
    border: none;
    li {
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .sider-menu {
    width: 100%;
    text-align: center;
    margin: 0;
  }
  .news-item {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .cover-wrap {
      width: 100% !important;
      height: auto !important;
      img {
        height: 100% !important;
      }
    }
    .detail-wrap {
      justify-content: space-between !important;
      width: 100% !important;
    }
  }
}
</style>
